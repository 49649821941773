/* width */
::-webkit-scrollbar {
	width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 50px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.NkAuthBody {
	border-radius: 15px;
	background-color: #fff;
	margin-top: 50px;
	border-color: #ecf0f3;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.BrandLogoTitle {
	color: #854fff;
	padding-top: 20px;
	/* padding-left: 20px; */
}

.card {
	box-shadow: none;
}

.dashboard_logo {
	margin-left: 50px;
}

.footer_website_link {
	font-weight: bold;
	transition: all 0.3s ease-in-out;
	color: #854fff !important;
}

.footer_website_link:hover,
.footer_website_link:focus {
	text-decoration: underline;
}

.header_dropdown_open {
	position: absolute;
	inset: 0px 0px auto auto;
	margin: 0px;
	transform: translate3d(-4.66667px, 38px, 0px);
}

.list_icon_style {
	font-size: 20px;
	font-weight: bold;
	cursor: pointer;
}

.map_view_icon,
.statistics_card_icon {
	font-weight: bold;
	font-size: 20px;
}

.cursor_pointer {
	cursor: pointer;
}

.alert_close_button {
	position: absolute;
	top: 2px;
	right: 7px;
}

.disabled_field {
	cursor: not-allowed;
	color: #000 !important;
	box-shadow: none !important;
	outline: none;
	border: none;
	background-color: #c0c0c3 !important;
}

.view_link {
	list-style-type: circle;
	cursor: pointer;
	color: #526484;
	margin-top: 5px;
	transition: all 0.3s ease-in-out;
}

.view_link:hover,
.view_link:focus {
	color: #854fff;
}

/* Delete Swal Modal */
.delete_modal.swal-modal,
.swal-overlay .swal-modal {
	width: 350px;
	border-radius: 5px;
}

.delete_modal .swal-title,
.delete_success_text .swal-title {
	color: #1c2b46;
	font-size: 22px;
	margin-bottom: 0;
	text-align: center;
}

.delete_modal .swal-text {
	text-align: center !important;
	color: #8094ae;
	font-size: 20px;
	padding: 10px 0;
}

.delete_success_text .swal-footer,
.delete_modal .swal-footer {
	text-align: center;
	margin-top: 0;
}

.delete_modal .swal-button--cancel {
	border-radius: 100px;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	color: #fff;
	display: flex;
	justify-content: center;
	outline: none !important;
	border: none !important;
	box-shadow: none !important;
	font-family: Inter, sans-serif !important;
	background-color: #1c2b46 !important;
}

.delete_modal .swal-button--danger {
	border-radius: 100px;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	display: flex;
	justify-content: center;
	outline: none !important;
	border: none !important;
	box-shadow: none !important;
	font-family: Inter, sans-serif !important;
	background-color: #e85347 !important;
}

.delete_success_text .swal-text {
	color: #1c2b46;
	font-size: 22px;
	margin-bottom: 0;
	text-align: center;
}

.success_button_ok {
	border-radius: 100px;
	width: 5rem;
	padding-top: 0.25rem;
	padding-bottom: 0.25rem;
	display: flex;
	justify-content: center;
	outline: none !important;
	border: none !important;
	box-shadow: none !important;
	background-color: #854fff !important;
}

.statistics_amount_size {
	font-size: 21px !important;
}

@media only screen and (max-width: 600px) {
	.NkAuthBody {
		margin-top: 0 !important;
	}
}
